export const ExpenseType = {
  Savings: "Savings",
  Housing: "Housing",
  Utilities: "Utilities",
  Food: "Food",
  Transport: "Transport",
  Insurance: "Insurance",
  PersonalHomeUpkeep: "Personal/Home Upkeep",
  Giving: "Giving",
  Medical: "Medical",
  Entertainment: "Entertainment",
};
