import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/api-service";
import { GroupedExpenses } from "../models/grouped-expenses";
import { Expense } from "../models/expense";
import { Settings } from "../models/settings";

export const fetchExpenses = createAsyncThunk<GroupedExpenses, string, {}>(
  "expenses/fetch",
  async (userId, thunkAPI) => {
    try {
      return await apiService.getExpensesByUser(userId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addExpense = createAction<Expense>("expenses/add");

export const updateExpense = createAction<Expense>("expenses/update");

export const deleteExpense = createAction<Expense>("expenses/delete");

export const fetchSettings = createAsyncThunk<Settings, string, {}>(
  "settings/fetch",
  async (userId, thunkAPI) => {
    try {
      return await apiService.getSettingsByUser(userId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateSettings = createAction<Settings>("settings/update");

export const setUserId = createAction<string>("userID");
