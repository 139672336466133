import { useEffect, useState, useCallback } from "react";
import styles from "./settings.module.scss";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import {
  TextField,
  Divider,
  InputAdornment,
  Button,
  Snackbar,
} from "@mui/material";
import {
  createCurrencyFormatter,
  unformatCurrency,
} from "../services/chart-service";
import { useDispatch } from "react-redux";
import { updateSettings } from "../store/actions";
import apiService from "../services/api-service";

export default function SettingsTab() {
  const dispatch = useDispatch<AppDispatch>();
  const [settings, setSettings] = useState(
    useSelector((state: RootState) => state.settings)
  );
  const [budgetPercents, setBudgetPercents] = useState({
    savingsBudget: 0,
    housingBudget: 0,
    utilitiesBudget: 0,
    foodBudget: 0,
    transportBudget: 0,
    insuranceBudget: 0,
    phUpkeepBudget: 0,
    givingBudget: 0,
    medicalBudget: 0,
    entertainmentBudget: 0,
  });
  const [totalBudget, setTotalBudget] = useState(0);
  const [totalBudgetPercent, setTotalBudgetPercent] = useState(0);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const calculateTotals = useCallback(() => {
    const totalBudget = Object.entries(settings)
      .filter(
        ([key]) => key !== "id" && key !== "userId" && key !== "monthlyIncome"
      )
      .reduce((acc, [, val]) => acc + (Number(val) || 0), 0);
    const totalBudgetPercent = Object.values(budgetPercents).reduce(
      (acc, val) => acc + (Number(val) || 0),
      0
    );
    setTotalBudget(totalBudget);
    setTotalBudgetPercent(totalBudgetPercent);
  }, [settings, budgetPercents]);

  useEffect(() => {
    const calculatePercentages = () => {
      const budgetFields = Object.entries(settings).filter(
        ([key]) => key !== "id" && key !== "userId" && key !== "monthlyIncome"
      );

      const totalBudget = settings.monthlyIncome;
      const newBudgetPercents: Record<string, number> = {};

      for (const [key, value] of budgetFields) {
        newBudgetPercents[key] = (value / totalBudget) * 100;
      }

      setBudgetPercents(
        newBudgetPercents as {
          savingsBudget: number;
          housingBudget: number;
          utilitiesBudget: number;
          foodBudget: number;
          transportBudget: number;
          insuranceBudget: number;
          phUpkeepBudget: number;
          givingBudget: number;
          medicalBudget: number;
          entertainmentBudget: number;
        }
      );
    };

    if (settings) {
      calculatePercentages();
      calculateTotals();
    }
  }, [settings, budgetPercents, calculateTotals]);

  const handleSave = async () => {
    const response = await apiService.updateSettings(settings);
    dispatch(updateSettings(response));
    setSnackBarOpen(true);
  };

  const handleSnackBarClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  const currencyFormatter = createCurrencyFormatter(0);

  return (
    <div>
      <div className={styles.income}>
        <TextField
          label="Monthly Income"
          value={currencyFormatter.format(settings.monthlyIncome)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, monthlyIncome: numValue });
            } else if (value === "") {
              setSettings({ ...settings, monthlyIncome: numValue });
            }
          }}
        />
      </div>
      <Divider></Divider>
      <div className={styles.budget}>
        <TextField
          label="Savings"
          value={currencyFormatter.format(settings.savingsBudget)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, savingsBudget: numValue });
            } else if (value === "") {
              setSettings({ ...settings, savingsBudget: numValue });
            }
          }}
        />
        <TextField
          label="Savings Percentage"
          value={
            isNaN(budgetPercents.savingsBudget)
              ? 0
              : budgetPercents.savingsBudget.toFixed(1)
          }
          disabled={true}
          onChange={(e) => {
            setBudgetPercents({
              ...budgetPercents,
              savingsBudget: Number(e.target.value),
            });
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          label="Housing"
          value={currencyFormatter.format(settings.housingBudget)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, housingBudget: numValue });
            } else if (value === "") {
              setSettings({ ...settings, housingBudget: numValue });
            }
          }}
        />
        <TextField
          label="Housing Percentage"
          value={
            isNaN(budgetPercents.housingBudget)
              ? 0
              : budgetPercents.housingBudget.toFixed(1)
          }
          disabled={true}
          onChange={(e) => {
            setBudgetPercents({
              ...budgetPercents,
              housingBudget: Number(e.target.value),
            });
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          label="Utilities"
          value={currencyFormatter.format(settings.utilitiesBudget)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, utilitiesBudget: numValue });
            } else if (value === "") {
              setSettings({ ...settings, utilitiesBudget: numValue });
            }
          }}
        />
        <TextField
          label="Utilities Percentage"
          value={
            isNaN(budgetPercents.utilitiesBudget)
              ? 0
              : budgetPercents.utilitiesBudget.toFixed(1)
          }
          disabled={true}
          onChange={(e) => {
            setBudgetPercents({
              ...budgetPercents,
              utilitiesBudget: Number(e.target.value),
            });
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          label="Food"
          value={currencyFormatter.format(settings.foodBudget)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, foodBudget: numValue });
            } else if (value === "") {
              setSettings({ ...settings, foodBudget: numValue });
            }
          }}
        />
        <TextField
          label="Food Percentage"
          value={
            isNaN(budgetPercents.foodBudget)
              ? 0
              : budgetPercents.foodBudget.toFixed(1)
          }
          disabled={true}
          onChange={(e) => {
            setBudgetPercents({
              ...budgetPercents,
              foodBudget: Number(e.target.value),
            });
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          label="Transport"
          value={currencyFormatter.format(settings.transportBudget)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, transportBudget: numValue });
            } else if (value === "") {
              setSettings({ ...settings, transportBudget: numValue });
            }
          }}
        />
        <TextField
          label="Transport Percentage"
          value={
            isNaN(budgetPercents.transportBudget)
              ? 0
              : budgetPercents.transportBudget.toFixed(1)
          }
          disabled={true}
          onChange={(e) => {
            setBudgetPercents({
              ...budgetPercents,
              transportBudget: Number(e.target.value),
            });
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          label="Insurance"
          value={currencyFormatter.format(settings.insuranceBudget)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, insuranceBudget: numValue });
            } else if (value === "") {
              setSettings({ ...settings, insuranceBudget: numValue });
            }
          }}
        />
        <TextField
          label="Insurance Percentage"
          value={
            isNaN(budgetPercents.insuranceBudget)
              ? 0
              : budgetPercents.insuranceBudget.toFixed(1)
          }
          disabled={true}
          onChange={(e) => {
            setBudgetPercents({
              ...budgetPercents,
              insuranceBudget: Number(e.target.value),
            });
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          label="Personal/Home Upkeep"
          value={currencyFormatter.format(settings.phUpkeepBudget)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, phUpkeepBudget: numValue });
            } else if (value === "") {
              setSettings({ ...settings, phUpkeepBudget: numValue });
            }
          }}
        />
        <TextField
          label="Personal/Home Upkeep Percentage"
          value={
            isNaN(budgetPercents.phUpkeepBudget)
              ? 0
              : budgetPercents.phUpkeepBudget.toFixed(1)
          }
          disabled={true}
          onChange={(e) => {
            setBudgetPercents({
              ...budgetPercents,
              phUpkeepBudget: Number(e.target.value),
            });
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          label="Giving"
          value={currencyFormatter.format(settings.givingBudget)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, givingBudget: numValue });
            } else if (value === "") {
              setSettings({ ...settings, givingBudget: numValue });
            }
          }}
        />
        <TextField
          label="Giving Percentage"
          value={
            isNaN(budgetPercents.givingBudget)
              ? 0
              : budgetPercents.givingBudget.toFixed(1)
          }
          disabled={true}
          onChange={(e) => {
            setBudgetPercents({
              ...budgetPercents,
              givingBudget: Number(e.target.value),
            });
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          label="Medical"
          value={currencyFormatter.format(settings.medicalBudget)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, medicalBudget: numValue });
            } else if (value === "") {
              setSettings({ ...settings, medicalBudget: numValue });
            }
          }}
        />
        <TextField
          label="Medical Percentage"
          value={
            isNaN(budgetPercents.medicalBudget)
              ? 0
              : budgetPercents.medicalBudget.toFixed(1)
          }
          disabled={true}
          onChange={(e) => {
            setBudgetPercents({
              ...budgetPercents,
              medicalBudget: Number(e.target.value),
            });
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <TextField
          label="Entertainment"
          value={currencyFormatter.format(settings.entertainmentBudget)}
          onChange={(e) => {
            const value = e.target.value;
            const numValue = Number(unformatCurrency(value));
            if (!isNaN(numValue)) {
              setSettings({ ...settings, entertainmentBudget: numValue });
            } else if (value === "") {
              setSettings({ ...settings, entertainmentBudget: numValue });
            }
          }}
        />
        <TextField
          label="Entertainment Percentage"
          value={
            isNaN(budgetPercents.entertainmentBudget)
              ? 0
              : budgetPercents.entertainmentBudget.toFixed(1)
          }
          disabled={true}
          onChange={(e) => {
            setBudgetPercents({
              ...budgetPercents,
              entertainmentBudget: Number(e.target.value),
            });
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </div>
      <div className={styles.budgetTotals}>
        <TextField
          label="Total"
          disabled={true}
          value={currencyFormatter.format(totalBudget)}
        />
        <TextField
          label="Total Percent"
          value={totalBudgetPercent.toFixed(1)}
          disabled={true}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </div>
      <Divider></Divider>
      <div className={styles.buttons}>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={handleSnackBarClose}
          message="Settings Saved"
        />
      </div>
    </div>
  );
}
