import { SyntheticEvent, useState, useEffect } from "react";
import { Tabs, Tab, IconButton, Menu, MenuItem } from "@mui/material";
import { TabView } from "./models/enums/tab-view";
import Expenses from "./current-month/expenses";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import Analysis from "./dashboard/analysis";
import { AppDispatch, RootState } from "./store/store";
import { fetchExpenses, fetchSettings, setUserId } from "./store/actions";
import apiService from "./services/api-service";
import SettingsTab from "./settings/settings";
import jwt_decode from "jwt-decode";
import { JwtPayload } from "jwt-decode";
import styles from "./Home.module.scss";
import MenuIcon from "@mui/icons-material/Menu";

export default function Home() {
  const dispatch = useDispatch<AppDispatch>();
  const expenses = useSelector((state: RootState) => state.expenses);
  const [selectedTab, setSelectedTab] = useState(TabView.Expenses);
  const [authenticated, setAuthenticated] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleTabChange = (
    _event: SyntheticEvent<Element, Event>,
    newTab: string
  ) => {
    setSelectedTab(newTab);
  };

  const renderTabContent = (tab: string) => {
    switch (tab) {
      case TabView.Expenses:
        return <Expenses expenses={expenses} />;
      case TabView.Analysis:
        return <Analysis expenses={expenses} />;
      case TabView.Settings:
        return <SettingsTab />;
      default:
        return <Analysis expenses={expenses} />;
    }
  };

  const handleLogout = () => {
    apiService.clearTokensAndLogout();
  };

  useEffect(() => {
    if (apiService.isIdTokenSet()) {
      const token = apiService.getIdToken();
      if (token) {
        const decodedToken: JwtPayload = jwt_decode(token);
        const userId = decodedToken.sub;
        if (userId) {
          dispatch(setUserId(userId));
          dispatch(fetchExpenses(userId)).then(() =>
            dispatch(fetchSettings(userId))
          );
        }
      }
      setAuthenticated(true);
    } else {
      const url = new URL(window.location.href);
      if (!url.searchParams.has("code")) {
        const authUrl = `https://login.fritzeenfinance.com/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Ffritzeenfinance.com`;
        window.location.href = authUrl;
      } else {
        const code = url.searchParams.get("code");

        apiService
          .getTokens(code)
          .then(() => {
            const token = apiService.getIdToken();
            if (token) {
              const decodedToken: JwtPayload = jwt_decode(token);
              const userId = decodedToken.sub;
              if (userId) {
                setAuthenticated(true);
                dispatch(setUserId(userId));
                dispatch(fetchExpenses(userId)).then(() =>
                  dispatch(fetchSettings(userId))
                );

                url.searchParams.delete("code");
                window.history.replaceState({}, "", url.toString());
              }
            }
          })
          .catch(console.error);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    const refreshTokenExpiry = apiService.getRefreshTokenExpireDate();
    if (refreshTokenExpiry) {
      const delay = refreshTokenExpiry.getTime() - Date.now();
      const refreshTokenTimeoutId = setTimeout(handleLogout, delay);
      return () => clearTimeout(refreshTokenTimeoutId);
    }
  }, []);

  useEffect(() => {
    if (apiService.isRefreshTokenExpired()) {
      handleLogout();
    }
  });

  if (!authenticated) {
    return null;
  }

  return (
    <div>
      <div className={styles.header}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab
            label={TabView.Expenses}
            value={TabView.Expenses}
            icon={<AttachMoneyIcon />}
          />
          <Tab
            label={TabView.Analysis}
            value={TabView.Analysis}
            icon={<BarChartIcon />}
          />
          <Tab
            label={TabView.Settings}
            value={TabView.Settings}
            icon={<SettingsIcon />}
          />
        </Tabs>
        <IconButton onClick={handleMenuClick}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
      {renderTabContent(selectedTab)}
    </div>
  );
}
