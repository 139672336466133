import { Typography, Card, CardContent } from "@mui/material";
import { GroupedExpenses } from "../../models/grouped-expenses";
import styles from "./insights.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  getCurrentMonthSpendingVsIncome,
  getOverBudgetInsights,
} from "../../services/insight-service";
import { useMemo } from "react";
import { createCurrencyFormatter } from "../../services/chart-service";

interface InsightsProps {
  expenses: GroupedExpenses;
}

export default function Insights(props: InsightsProps) {
  const settings = useSelector((state: RootState) => state.settings);

  const currentMonthSpendingVsIncome = useMemo(
    () => getCurrentMonthSpendingVsIncome(props.expenses, settings),
    [props.expenses, settings]
  );

  const overBudgetInsights = useMemo(
    () => getOverBudgetInsights(props.expenses, settings),
    [props.expenses, settings]
  );

  return (
    <div className={styles.insights}>
      <Card>
        <CardContent>
          <div className={styles.totalBudgetCard}>
            <Typography
              className={
                currentMonthSpendingVsIncome > 0 ? styles.green : styles.red
              }
            >
              {createCurrencyFormatter(2).format(currentMonthSpendingVsIncome)}
            </Typography>
            <Typography>
              {`${
                currentMonthSpendingVsIncome > 0 ? "Under " : "Over "
              } Monthly Budget`}
            </Typography>
          </div>
        </CardContent>
      </Card>
      {overBudgetInsights.length ? (
        <Card>
          <CardContent>
            {getOverBudgetInsights(props.expenses, settings).map(
              (overage, index) => {
                return (
                  <Typography key={index}>
                    {`${overage.type} over budget for past ${
                      overage.overBudgetMonthsCount
                    } month${overage.overBudgetMonthsCount !== 1 ? "s" : ""}`}
                  </Typography>
                );
              }
            )}
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
}
