import { ExpenseType } from "../../models/enums/expense-type";
import { Expense } from "../../models/expense";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogActions,
  Button,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "./expense-dialog.module.scss";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import apiService from "../../services/api-service";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import { addExpense, updateExpense } from "../../store/actions";
import { RecurringExpenseFrequency } from "../../models/enums/recurring-expense-frequency";

interface ExpenseDialogProps {
  open: boolean;
  handleClick: () => void;
  expense: Expense | null;
  isNew: boolean;
}

interface ErrorState {
  name: string | null;
  amount: string | null;
  type: string | null;
}

export default function ExpenseDialog(props: ExpenseDialogProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [expense, setExpense] = useState<Expense | null>(props.expense);
  const [amount, setAmount] = useState<string>("");
  const [errors, setErrors] = useState<ErrorState>({
    name: null,
    amount: null,
    type: null,
  });

  useEffect(() => {
    setExpense(props.expense);
    if (props.expense) {
      setAmount(
        props.expense.amount === 0 ? "" : props.expense.amount.toString()
      );
    }
  }, [props.expense]);

  const handleSave = async () => {
    if (expense) {
      const newErrors: ErrorState = {
        name: null,
        amount: null,
        type: null,
      };

      if (!expense.name) newErrors.name = "Name cannot be empty";

      const parsedAmount = parseFloat(amount);
      if (isNaN(parsedAmount) || parsedAmount === 0) {
        newErrors.amount = "Amount must be a non-zero number";
      }

      if (!expense.type) newErrors.type = "Type cannot be empty";

      if (newErrors.name || newErrors.amount || newErrors.type) {
        setErrors(newErrors);
        return;
      }

      const updatedExpense = { ...expense, amount: parsedAmount };
      if (props.isNew) {
        const response = await apiService.addExpense(updatedExpense);
        dispatch(addExpense(response));
      } else {
        const response = await apiService.updateExpense(updatedExpense);
        dispatch(updateExpense(response));
      }
    }
    props.handleClick();
    clearState();
  };

  const clearState = () => {
    setExpense(null);
    setAmount("");
    setErrors({
      name: null,
      amount: null,
      type: null,
    });
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleClick();
        clearState();
      }}
    >
      <DialogTitle>{props.isNew ? "Add Expense" : "Edit Expense"}</DialogTitle>
      <DialogContent sx={{ paddingBottom: "0px" }}>
        {expense && (
          <>
            <div className={styles.nameAmount}>
              <TextField
                label="Name"
                value={expense.name}
                onChange={(e) => {
                  setExpense({ ...expense, name: e.target.value });
                }}
                error={!!errors.name}
                helperText={errors.name}
              />
              <TextField
                label="Amount"
                value={amount || ""}
                onChange={(e) => {
                  if (amount === "") {
                    setAmount(e.target.value.replace(/^0+/, ""));
                  } else {
                    setAmount(e.target.value);
                  }
                }}
                error={!!errors.amount}
                helperText={errors.amount}
              />
            </div>
            <div className={styles.typeNecessity}>
              <FormControl fullWidth error={!!errors.type}>
                <InputLabel>Type</InputLabel>
                <Select
                  value={expense.type}
                  label="Type"
                  onChange={(e) => {
                    setExpense({ ...expense, type: e.target.value });
                  }}
                >
                  {Object.values(ExpenseType).map((type, index) => {
                    return (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.type}</FormHelperText>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(expense.date)}
                  label="Date"
                  onChange={(date) => {
                    if (date) {
                      setExpense({ ...expense, date: date.toDate() });
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className={styles.recurring}>
              <FormControlLabel
                checked={expense.recurring}
                control={<Checkbox />}
                label="Recurring"
                onChange={(_e, checked) => {
                  setExpense({
                    ...expense,
                    recurring: checked,
                    recurringFrequency: checked
                      ? RecurringExpenseFrequency.Monthly
                      : "",
                  });
                }}
              />
              {expense.recurring ? (
                <FormControl>
                  <FormLabel>Frequency</FormLabel>
                  <RadioGroup
                    row
                    defaultValue={
                      expense.recurringFrequency ||
                      RecurringExpenseFrequency.Monthly
                    }
                    onChange={(e) => {
                      setExpense({
                        ...expense,
                        recurringFrequency: e.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={RecurringExpenseFrequency.Monthly}
                      control={<Radio />}
                      label={RecurringExpenseFrequency.Monthly}
                    />
                    <FormControlLabel
                      value={RecurringExpenseFrequency.Yearly}
                      control={<Radio />}
                      label={RecurringExpenseFrequency.Yearly}
                    />
                  </RadioGroup>
                </FormControl>
              ) : null}
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.handleClick();
            clearState();
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
