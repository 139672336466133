import { createReducer } from "@reduxjs/toolkit";
import { GroupedExpenses } from "../models/grouped-expenses";
import {
  addExpense,
  deleteExpense,
  fetchExpenses,
  fetchSettings,
  setUserId,
  updateExpense,
  updateSettings,
} from "./actions";
import { monthNames } from "../services/chart-service";
import { Settings } from "../models/settings";
import { budgetTypeKeyMap } from "../models/budget-type-key-map";

interface StoreState {
  expenses: GroupedExpenses;
  settings: Settings;
  budgetByType: BudgetByType;
  userId: string;
}

type BudgetByType = {
  [key: string]: number;
};

const initialState: StoreState = {
  expenses: {},
  settings: {
    id: "",
    userId: "",
    monthlyIncome: 0,
    savingsBudget: 0,
    housingBudget: 0,
    utilitiesBudget: 0,
    foodBudget: 0,
    transportBudget: 0,
    insuranceBudget: 0,
    phUpkeepBudget: 0,
    givingBudget: 0,
    medicalBudget: 0,
    entertainmentBudget: 0,
  },
  budgetByType: {},
  userId: "",
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchExpenses.fulfilled, (state, action) => {
      state.expenses = action.payload;
    })
    .addCase(addExpense, (state, action) => {
      const newExpense = {
        ...action.payload,
        date: new Date(action.payload.date),
      };
      const year = newExpense.date.getFullYear();
      const month = monthNames[newExpense.date.getMonth()];

      if (!state.expenses[year]) {
        state.expenses[year] = {};
      }

      if (!state.expenses[year][month]) {
        state.expenses[year][month] = [];
      }

      state.expenses[year][month].push(newExpense);
    })
    .addCase(updateExpense, (state, action) => {
      const newExpense = {
        ...action.payload,
        date: new Date(action.payload.date),
      };
      const year = newExpense.date.getFullYear();
      const month = monthNames[newExpense.date.getMonth()];
      const index = state.expenses[year]?.[month].findIndex(
        (expense) => expense.id === newExpense.id
      );
      if (index !== -1) {
        state.expenses[year][month][index] = newExpense;
      }
    })
    .addCase(deleteExpense, (state, action) => {
      const newExpense = {
        ...action.payload,
        date: new Date(action.payload.date),
      };
      const year = newExpense.date.getFullYear();
      const month = monthNames[newExpense.date.getMonth()];
      const index = state.expenses[year]?.[month].findIndex(
        (expense) => expense.id === newExpense.id
      );
      if (index !== -1) {
        state.expenses[year][month] = [
          ...state.expenses[year][month].slice(0, index),
          ...state.expenses[year][month].slice(index + 1),
        ];
      }
    })
    .addCase(fetchSettings.fulfilled, (state, action) => {
      state.settings = action.payload;
      state.budgetByType = convertBudgetSettingsToBudgetByType(action.payload);
    })
    .addCase(updateSettings, (state, action) => {
      state.settings = action.payload;
      state.budgetByType = convertBudgetSettingsToBudgetByType(action.payload);
    })
    .addCase(setUserId, (state, action) => {
      state.userId = action.payload;
    });
});

function convertBudgetSettingsToBudgetByType(apiResponse: Settings) {
  let budgetByType: { [key: string]: number } = {};

  for (let key in apiResponse) {
    if (
      key in budgetTypeKeyMap &&
      key !== "id" &&
      key !== "userId" &&
      key !== "monthlyIncome"
    ) {
      budgetByType[budgetTypeKeyMap[key]] = apiResponse[
        key as keyof Settings
      ] as number;
    }
  }

  return budgetByType;
}
