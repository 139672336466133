import { Settings } from "../models/settings";
import { budgetTypeKeyMap } from "../models/budget-type-key-map";
import { getCurrentMonthAndLastXMonths, monthNames } from "./chart-service";
import { GroupedExpenses } from "../models/grouped-expenses";

export const getOverBudgetInsights = (
  expenses: GroupedExpenses,
  settings: Settings
) => {
  const overBudgetInsights = [];
  const monthsWithYear = getCurrentMonthAndLastXMonths(11);

  for (const budgetKey in budgetTypeKeyMap) {
    if (
      budgetKey === "savingsBudget" ||
      budgetKey === "id" ||
      budgetKey === "monthlyIncome" ||
      budgetKey === "userId"
    )
      continue;

    let overBudgetMonthsCount = 0;
    let totalOverspend = 0;
    let isPreviousMonthOverBudget = false;

    const expenseType = budgetTypeKeyMap[budgetKey];

    for (const monthYear of monthsWithYear) {
      const [month, year] = monthYear.split(" ");
      const budget = Number(settings[budgetKey as keyof Settings]);
      const expensesInMonth = expenses[year]?.[month] || [];

      const totalForType = expensesInMonth
        .filter((expense) => expense.type === expenseType)
        .reduce((sum, expense) => sum + expense.amount, 0);

      const isCurrentMonthOverBudget = totalForType > budget;

      if (isCurrentMonthOverBudget) {
        overBudgetMonthsCount++;
        totalOverspend += totalForType - budget;
        isPreviousMonthOverBudget = true;
      } else if (isPreviousMonthOverBudget) {
        break;
      }
    }

    if (overBudgetMonthsCount > 1) {
      overBudgetInsights.push({
        type: expenseType,
        overBudgetMonthsCount,
        averageOverspend: Math.round(totalOverspend / overBudgetMonthsCount),
      });
    }
  }

  return overBudgetInsights;
};

export const getCurrentMonthSpendingVsIncome = (
  expenses: GroupedExpenses,
  settings: Settings
) => {
  let currentMonth = monthNames[new Date().getMonth()];
  let currentYear = new Date().getFullYear();

  let totalSpending = 0;

  const currentMonthExpenses = expenses[currentYear]?.[currentMonth];

  if (currentMonthExpenses) {
    for (const expense of currentMonthExpenses) {
      totalSpending += expense.amount;
    }
  }

  return Number(settings.monthlyIncome) - totalSpending;
};
