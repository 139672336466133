import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import styles from "./expenses.module.scss";
import { GroupedExpenses } from "../models/grouped-expenses";
import ExpensesTable from "./expenses-table/expenses-table";
import {
  getAllMonthYears,
  getCurrentMonthYear,
  getExpensesByMonth,
} from "../services/chart-service";
import { Expense } from "../models/expense";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

interface ExpensesProps {
  expenses: GroupedExpenses;
}

export default function Expenses(props: ExpensesProps) {
  const userId = useSelector((state: RootState) => state.userId);
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    getCurrentMonthYear()
  );
  const [selectedMonthExpenses, setSelectedMonthExpenses] = useState<Expense[]>(
    []
  );
  const [selectedExpense, setSelectedExpense] = useState<Expense | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setSelectedMonthExpenses(
      getExpensesByMonth(selectedMonthYear, props.expenses)
    );
  }, [selectedMonthYear, props.expenses]);

  const currentMonthYear = useMemo(() => getCurrentMonthYear(), []);
  const [currentMonth, currentYear] = useMemo(
    () => currentMonthYear.split(" "),
    [currentMonthYear]
  );

  const handleAddClick = () => {
    setSelectedExpense({
      id: uuidv4(),
      userId: userId,
      name: "",
      amount: 0,
      recurring: false,
      type: "",
      date: new Date(),
      recurringFrequency: "",
    });
    setDialogOpen(true);
  };

  return (
    <div>
      <div className={styles.addExpense}>
        <FormControl>
          <InputLabel id="month-label">Month</InputLabel>
          {Object.keys(props.expenses).length > 0 && (
            <Select
              size="small"
              labelId="month-label"
              id="month-select"
              value={selectedMonthYear}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
              label="Month"
              onChange={(event) => setSelectedMonthYear(event.target.value)}
            >
              {props.expenses[currentYear][currentMonth]?.length ? null : (
                <MenuItem key={currentMonthYear} value={currentMonthYear}>
                  {currentMonthYear}
                </MenuItem>
              )}
              {getAllMonthYears(props.expenses).map((monthYear) => (
                <MenuItem key={monthYear} value={monthYear}>
                  {monthYear}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handleAddClick}
        >
          Add Expense
        </Button>
      </div>
      <ExpensesTable
        expenses={selectedMonthExpenses}
        selectedExpense={selectedExpense}
        setSelectedExpense={setSelectedExpense}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </div>
  );
}
