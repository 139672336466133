import { useEffect, useMemo, useState } from "react";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Expense } from "../../../models/expense";
import {
  getAllMonthYears,
  getCurrentMonthYear,
  getTotalExpensesByTypeAndMonth,
} from "../../../services/chart-service";
import styles from "./expense-type-by-month-vs-budget.module.scss";
import { ExpenseType } from "../../../models/enums/expense-type";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface ExpenseTypeByMonthVsBudgetProps {
  expenses: {
    [year: string]: { [month: string]: Expense[] };
  };
}

export default function ExpenseTypeByMonthVsBudget(
  props: ExpenseTypeByMonthVsBudgetProps
) {
  const budgetByType = useSelector((state: RootState) => state.budgetByType);
  const budgetValues = Object.values(budgetByType);
  const currentMonthYear = useMemo(() => getCurrentMonthYear(), []);
  const [currentMonth, currentYear] = useMemo(
    () => currentMonthYear.split(" "),
    [currentMonthYear]
  );
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    getCurrentMonthYear()
  );
  const spentValues = getTotalExpensesByTypeAndMonth(
    selectedMonthYear,
    props.expenses
  );

  const [chartOptions, setChartOptions] = useState<ApexCharts.ApexOptions>({
    series: [
      {
        name: "Budget",
        data: budgetValues,
      },
      {
        name: "Spent",
        data: spentValues,
      },
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: [
      function ({
        value,
        seriesIndex,
        w,
      }: {
        value: number;
        seriesIndex: number;
        w: any;
      }) {
        if (seriesIndex === 1) {
          const category =
            Object.keys(budgetByType)[
              w.config.series[seriesIndex].data.indexOf(value)
            ];
          if (category === ExpenseType.Savings) {
            return value >=
              budgetValues[w.config.series[seriesIndex].data.indexOf(value)]
              ? "#26BB73"
              : "#CC3928";
          } else {
            return value >
              budgetValues[w.config.series[seriesIndex].data.indexOf(value)]
              ? "#CC3928"
              : "#26BB73";
          }
        }
        return "#62726A";
      },
    ],
    dataLabels: {
      enabled: true,
      offsetX: 30,
      style: {
        fontSize: "12px",
        colors: ["black"],
      },
    },
    responsive: [
      {
        breakpoint: 800,
        options: {
          dataLabels: {
            enabled: true,
            offsetX: 30,
            style: {
              fontSize: "10px",
              colors: ["black"],
            },
          },
        },
      },
    ],
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: Object.keys(budgetByType),
    },
  });

  useEffect(() => {
    const updateChart = () => {
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            name: "Budget",
            data: Object.values(budgetByType),
          },
          {
            name: "Spent",
            data: getTotalExpensesByTypeAndMonth(
              selectedMonthYear,
              props.expenses
            ),
          },
        ],
      }));
    };

    updateChart();
  }, [props.expenses, selectedMonthYear, budgetByType]);

  return (
    <div>
      <FormControl>
        <InputLabel id="month-label">Month</InputLabel>
        {Object.keys(props.expenses).length > 0 && (
          <Select
            size="small"
            labelId="month-label"
            id="month-select"
            value={selectedMonthYear}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
            label="Month"
            onChange={(event) => setSelectedMonthYear(event.target.value)}
          >
            {props.expenses[currentYear][currentMonth]?.length ? null : (
              <MenuItem key={currentMonthYear} value={currentMonthYear}>
                {currentMonthYear}
              </MenuItem>
            )}
            {getAllMonthYears(props.expenses).map((monthYear) => (
              <MenuItem key={monthYear} value={monthYear}>
                {monthYear}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      <ReactApexChart
        className={styles.chart}
        options={chartOptions}
        series={chartOptions.series}
        type="bar"
        height={window.innerHeight * 0.5}
      />
    </div>
  );
}
