import axios, { AxiosInstance } from "axios";
import { Expense } from "../models/expense";
import { GroupedExpenses } from "../models/grouped-expenses";
import qs from "qs";
import { Settings } from "../models/settings";

class ApiService {
  private api: AxiosInstance;
  private idToken: string | null = null;
  private refreshToken: string | null = null;
  private refreshTokenExpireDate: Date | null = null;
  private idTokenStorageKey = "id_token";
  private refreshTokenStorageKey = "refresh_token";
  private refreshTokenExpireStorageKey = "refresh_token_expire";

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });

    this.api.interceptors.request.use((request) => {
      if (this.idToken) {
        request.headers["Authorization"] = `Bearer ${this.idToken}`;
      }
      return request;
    });

    const idToken = localStorage.getItem(this.idTokenStorageKey);
    const refreshToken = localStorage.getItem(this.refreshTokenStorageKey);
    this.setTokens(idToken, refreshToken);
  }

  public async getExpensesByUser(userId: string): Promise<GroupedExpenses> {
    try {
      const response = await this.api.get(`expense/${userId}`);
      return this.groupExpensesByMonthAndYear(response.data);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await this.refreshTokens();
        return this.getExpensesByUser(userId);
      }

      console.error(`Error occurred while getting expenses: ${error}`);
      throw error;
    }
  }

  public async addExpense(expense: Expense): Promise<Expense> {
    try {
      const response = await this.api.post("expense", expense);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await this.refreshTokens();
        return this.addExpense(expense);
      }

      console.error(`Error occurred while adding expense: ${error}`);
      throw error;
    }
  }

  public async updateExpense(expense: Expense): Promise<Expense> {
    try {
      const response = await this.api.put(`expense/${expense.id}`, expense);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await this.refreshTokens();
        return this.updateExpense(expense);
      }

      console.error(`Error occurred while updating expense: ${error}`);
      throw error;
    }
  }

  public async deleteExpense(expense: Expense): Promise<void> {
    try {
      await this.api.delete(`expense/${expense.id}`);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await this.refreshTokens();
        return this.deleteExpense(expense);
      }

      console.error(`Error occurred while deleting expense: ${error}`);
      throw error;
    }
  }

  public async getSettingsByUser(userId: string): Promise<Settings> {
    try {
      const response = await this.api.get(`settings/${userId}`);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await this.refreshTokens();
        return this.getSettingsByUser(userId);
      }

      console.error(`Error occurred while getting settings: ${error}`);
      throw error;
    }
  }

  public async updateSettings(settings: Settings): Promise<Settings> {
    try {
      const response = await this.api.put("settings", settings);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        await this.refreshTokens();
        return this.updateSettings(settings);
      }

      console.error(`Error occurred while updating settings: ${error}`);
      throw error;
    }
  }

  public async getTokens(code: string | null): Promise<void> {
    try {
      const response = await axios.post(
        "https://login.fritzeenfinance.com/oauth2/token",
        qs.stringify({
          grant_type: "authorization_code",
          client_id: process.env.REACT_APP_CLIENT_ID,
          code: code,
          redirect_uri: "https://fritzeenfinance.com",
        }),
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      );

      const { id_token, refresh_token } = response.data;
      this.setTokens(id_token, refresh_token);
    } catch (error) {
      console.error(`Error occurred while getting tokens: ${error}`);
      throw error;
    }
  }

  private async refreshTokens(): Promise<void> {
    try {
      if (!this.refreshToken) {
        console.error("Refresh token is not set");
        this.clearTokensAndLogout();
      }

      const response = await axios.post(
        "https://login.fritzeenfinance.com/oauth2/token",
        qs.stringify({
          grant_type: "refresh_token",
          client_id: process.env.REACT_APP_CLIENT_ID,
          refresh_token: this.refreshToken,
        }),
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      );

      const { id_token, refresh_token } = response.data;
      this.setTokens(id_token, refresh_token);
    } catch (error) {
      console.error(`Error occurred while refreshing tokens: ${error}`);
      this.clearTokensAndLogout();
    }
  }

  public clearTokensAndLogout(): void {
    this.idToken = null;
    this.refreshToken = null;
    this.refreshTokenExpireDate = null;
    localStorage.removeItem(this.idTokenStorageKey);
    localStorage.removeItem(this.refreshTokenStorageKey);
    localStorage.removeItem(this.refreshTokenExpireStorageKey);

    const logoutUrl =
      "https://login.fritzeenfinance.com/logout?" +
      "response_type=code&" +
      "scope=email+openid+phone&" +
      `client_id=${process.env.REACT_APP_CLIENT_ID}&` +
      `redirect_uri=${encodeURIComponent("https://fritzeenfinance.com")}`;

    window.location.href = logoutUrl;
  }

  public isIdTokenSet(): boolean {
    if (!this.idToken) {
      if (!localStorage.getItem(this.idTokenStorageKey)) {
        return false;
      }
      const idToken = localStorage.getItem(this.idTokenStorageKey);
      const refreshToken = localStorage.getItem(this.refreshTokenStorageKey);
      this.setTokens(idToken, refreshToken);
    }
    return true;
  }

  public getIdToken(): string | null {
    return this.idToken;
  }

  public isRefreshTokenExpired(): boolean {
    if (!this.refreshTokenExpireDate) {
      return false;
    }
    const now = new Date();
    return now > this.refreshTokenExpireDate;
  }

  public getRefreshTokenExpireDate(): Date | null {
    return this.refreshTokenExpireDate;
  }

  public setTokens(idToken: string | null, refreshToken: string | null) {
    if (idToken) {
      this.idToken = idToken;
      localStorage.setItem(this.idTokenStorageKey, idToken);
    }

    if (refreshToken) {
      this.refreshToken = refreshToken;
      localStorage.setItem(this.refreshTokenStorageKey, refreshToken);

      var storedExpiration = localStorage.getItem(
        this.refreshTokenExpireStorageKey
      );

      if (!this.refreshTokenExpireDate && !storedExpiration) {
        var currentTime = new Date();

        let totalMilliseconds =
          13 * 24 * 60 * 60 * 1000 + // 13 days
          23 * 60 * 60 * 1000 + // 23 hours
          59 * 60 * 1000; // 59 minutes
        currentTime.setTime(currentTime.getTime() + totalMilliseconds);
        localStorage.setItem(
          this.refreshTokenExpireStorageKey,
          currentTime.toISOString()
        );
        this.refreshTokenExpireDate = currentTime;
      } else if (storedExpiration) {
        this.refreshTokenExpireDate = new Date(storedExpiration);
      }
    }
  }

  private groupExpensesByMonthAndYear(expenses: Expense[]): GroupedExpenses {
    return expenses.reduce<{
      [year: string]: { [month: string]: Expense[] };
    }>((grouped, expense) => {
      const date = new Date(expense.date);
      const month = date.toLocaleString("en-US", { month: "short" });
      const year = date.getFullYear().toString();

      grouped[year] = grouped[year] || {};
      (grouped[year][month] = grouped[year][month] || []).push(expense);

      return grouped;
    }, {});
  }
}

const apiService = new ApiService();
export default apiService;
