import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridSortItem } from "@mui/x-data-grid";
import { Expense } from "../../models/expense";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpenseDialog from "../expense-dialog/expense-dialog";
import { useState, useEffect } from "react";
import { createCurrencyFormatter } from "../../services/chart-service";
import ConfirmDialog from "../confirm-dialog/confirm-dialog";
import ExpensesTableMobile from "../expenses-table-mobile/expenses-table-mobile";

interface ExpensesTableProps {
  expenses: Expense[];
  selectedExpense: Expense | null;
  setSelectedExpense: (expense: Expense | null) => void;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
}

export default function ExpensesTable(props: ExpensesTableProps) {
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: "date",
      sort: "desc",
    },
  ]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [expenseToDelete, setExpenseToDelete] = useState<Expense | null>(null);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "edit",
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          props.setSelectedExpense(params.row as Expense);
          props.setDialogOpen(true);
        };

        return (
          <IconButton onClick={onClick}>
            <EditIcon />
          </IconButton>
        );
      },
      headerName: "",
      disableColumnMenu: true,
      width: 60,
    },
    { field: "name", headerName: "Name", width: windowWidth * 0.2 },
    {
      field: "amount",
      headerName: "Amount",
      width: windowWidth * 0.1,
      type: "number",
      valueFormatter: (amount) => {
        const currencyFormatter = createCurrencyFormatter(2);
        return currencyFormatter.format(amount.value);
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: windowWidth * 0.175,
    },
    {
      field: "recurring",
      headerName: "Recurring",
      type: "boolean",
    },
    {
      field: "date",
      headerName: "Date",
      type: "date",
      valueGetter: (date) => {
        return new Date(date.value);
      },
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          setExpenseToDelete(params.row as Expense);
        };

        return (
          <IconButton onClick={onClick}>
            <DeleteIcon />
          </IconButton>
        );
      },
      disableColumnMenu: true,
    },
  ];

  const handleCloseDialog = () => {
    props.setSelectedExpense(null);
    props.setDialogOpen(false);
  };

  if (windowWidth <= 800) {
    return (
      <ExpensesTableMobile
        expenses={props.expenses}
        selectedExpense={props.selectedExpense}
        setSelectedExpense={props.setSelectedExpense}
        dialogOpen={props.dialogOpen}
        setDialogOpen={props.setDialogOpen}
      />
    );
  } else {
    return (
      <Box sx={{ width: windowWidth - 10, height: window.innerHeight * 0.75 }}>
        <DataGrid
          rows={props.expenses}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30]}
          onSortModelChange={(model) => setSortModel(model)}
          sortModel={sortModel}
        />
        <ExpenseDialog
          open={props.dialogOpen}
          expense={props.selectedExpense}
          isNew={props.selectedExpense?.name === ""}
          handleClick={handleCloseDialog}
        />
        <ConfirmDialog
          open={!!expenseToDelete}
          handleClose={() => setExpenseToDelete(null)}
          expenseToDelete={expenseToDelete}
        />
      </Box>
    );
  }
}
