import { useState, useEffect } from "react";
import styles from "./analysis.module.scss";
import ExpenseTypeByMonthVsBudget from "./charts/expense-type-by-month-vs-budget/expense-type-by-month-vs-budget";
import { GroupedExpenses } from "../models/grouped-expenses";
import TotalMonthlySpendingHistory from "./charts/total-monthly-spending-history/total-monthly-spending-history";
import TotalSpendingHistoryByExpense from "./charts/total-spending-history-by-expense/total-spending-history-by-expense";
import Insights from "./insights/insights";

interface AnalysisProps {
  expenses: GroupedExpenses;
}

export default function Analysis(props: AnalysisProps) {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return (
    <div className={styles.chartArea}>
      <Insights expenses={props.expenses}></Insights>
      {isClient && (
        <div className={styles.charts}>
          <ExpenseTypeByMonthVsBudget expenses={props.expenses} />
          <TotalSpendingHistoryByExpense
            expenses={props.expenses}
          ></TotalSpendingHistoryByExpense>
          <TotalMonthlySpendingHistory
            expenses={props.expenses}
          ></TotalMonthlySpendingHistory>
        </div>
      )}
    </div>
  );
}
