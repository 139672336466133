import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Expense } from "../../../models/expense";
import {
  getCurrentMonthAndLastXMonths,
  transformExpensesToSeries,
} from "../../../services/chart-service";
import styles from "./total-monthly-spending-history.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface TotalMonthlySpendingHistoryProps {
  expenses: {
    [year: string]: { [month: string]: Expense[] };
  };
}

export default function TotalMonthlySpendingHistory(
  props: TotalMonthlySpendingHistoryProps
) {
  const budgetByType = useSelector((state: RootState) => state.budgetByType);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);
  const [series, setSeries] = useState(
    transformExpensesToSeries(props.expenses, isSmallScreen)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setSeries(transformExpensesToSeries(props.expenses, isSmallScreen));
  }, [props.expenses, isSmallScreen]);

  const budgetMax = Object.values(budgetByType).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const options: ApexCharts.ApexOptions = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    responsive: [
      {
        breakpoint: 800,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      type: "category",
      categories: getCurrentMonthAndLastXMonths(isSmallScreen ? 5 : 11),
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
    annotations: {
      yaxis: [
        {
          y: budgetMax,
          borderColor: "#FF0000",
          label: {
            borderColor: "#FF0000",
            style: {
              color: "#fff",
              background: "#FF0000",
            },
            text: `Budget ($${budgetMax})`,
          },
        },
      ],
    },
  };

  return (
    <ReactApexChart
      className={styles.chart}
      options={options}
      series={series}
      type="bar"
      height={window.innerHeight * 0.75}
    />
  );
}
