import { IconButton, Divider, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Expense } from "../../models/expense";
import { useState } from "react";
import ExpenseDialog from "../expense-dialog/expense-dialog";
import { createCurrencyFormatter } from "../../services/chart-service";
import ConfirmDialog from "../confirm-dialog/confirm-dialog";
import styles from "./expenses-table-mobile.module.scss";

interface ExpensesTableMobileProps {
  expenses: Expense[];
  selectedExpense: Expense | null;
  setSelectedExpense: (expense: Expense | null) => void;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
}

export default function ExpensesTableMobile(props: ExpensesTableMobileProps) {
  const [expenseToDelete, setExpenseToDelete] = useState<Expense | null>(null);

  const handleOpenDialog = (expense: Expense) => {
    props.setSelectedExpense(expense);
    props.setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    props.setSelectedExpense(null);
    props.setDialogOpen(false);
  };

  const sortedExpenses = [...props.expenses].sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return (
    <div>
      <Divider></Divider>
      {sortedExpenses.map((expense, index) => {
        return (
          <div key={index}>
            <div className={styles.expenseCard}>
              <div className={styles.buttons}>
                <IconButton onClick={() => handleOpenDialog(expense)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => setExpenseToDelete(expense)}>
                  <DeleteIcon />
                </IconButton>
              </div>
              <div className={styles.expenseInfo}>
                <Typography variant="h6">{expense.name}</Typography>
                <div className={styles.twoFields}>
                  <Typography>
                    {createCurrencyFormatter(2).format(expense.amount)}
                  </Typography>
                  <Typography>{expense.type}</Typography>
                </div>
                <div className={styles.twoFields}>
                  <Typography variant="body2">
                    {new Date(expense.date).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2">
                    {expense.recurring ? "Recurring" : "Non-Recurring"}
                  </Typography>
                </div>
              </div>
            </div>
            <Divider></Divider>
          </div>
        );
      })}
      <ExpenseDialog
        open={props.dialogOpen}
        expense={props.selectedExpense}
        isNew={props.selectedExpense?.name === ""}
        handleClick={handleCloseDialog}
      />
      <ConfirmDialog
        open={!!expenseToDelete}
        handleClose={() => setExpenseToDelete(null)}
        expenseToDelete={expenseToDelete}
      />
    </div>
  );
}
