export const budgetTypeKeyMap: { [key: string]: string } = {
  savingsBudget: "Savings",
  housingBudget: "Housing",
  utilitiesBudget: "Utilities",
  foodBudget: "Food",
  transportBudget: "Transport",
  insuranceBudget: "Insurance",
  phUpkeepBudget: "Personal/Home Upkeep",
  givingBudget: "Giving",
  medicalBudget: "Medical",
  entertainmentBudget: "Entertainment",
};
