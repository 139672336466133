import { ExpenseType } from "../models/enums/expense-type";
import { Expense } from "../models/expense";
import { GroupedExpenses } from "../models/grouped-expenses";

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getCurrentMonthYear = () => {
  const now = new Date();
  return `${monthNames[now.getMonth()]} ${now.getFullYear()}`;
};

export const getAllMonthYears = (expenses: GroupedExpenses) => {
  const monthYears = [];

  for (const year in expenses) {
    const months = expenses[year];

    for (const month in months) {
      monthYears.push(`${month} ${year}`);
    }
  }

  monthYears.sort((a, b) => {
    const [monthA, yearA] = a.split(" ");
    const [monthB, yearB] = b.split(" ");

    const dateA = new Date(Number(yearA), monthNames.indexOf(monthA));
    const dateB = new Date(Number(yearB), monthNames.indexOf(monthB));

    return dateB.getTime() - dateA.getTime();
  });

  return monthYears;
};

export const getCurrentMonthAndLastXMonths = (additionalMonths: number) => {
  const monthsWithYear: string[] = [];
  const now = new Date();
  for (let i = 0; i < additionalMonths + 1; i++) {
    let currentMonth = now.getMonth() - i;
    let year = now.getFullYear();
    if (currentMonth < 0) {
      currentMonth += 12;
      year--;
    }
    monthsWithYear.push(`${monthNames[currentMonth]} ${year}`);
  }
  return monthsWithYear;
};

export const getSpendingForExpenseType = (
  expenses: { [year: string]: { [month: string]: Expense[] } },
  selectedExpense: string
) => {
  const monthsWithYear = getCurrentMonthAndLastXMonths(11);
  const spending = monthsWithYear.map((monthYear) => {
    const [month, year] = monthYear.split(" ");
    if (expenses[year] && expenses[year][month]) {
      return expenses[year][month]
        .filter((expense) => expense.type === selectedExpense)
        .reduce(
          (sum, currentExpense) => sum + Math.round(currentExpense.amount),
          0
        );
    }
    return 0;
  });
  return spending;
};

export const transformExpensesToSeries = (
  expenses: GroupedExpenses,
  isSmallScreen: boolean
) => {
  const monthsWithYear = getCurrentMonthAndLastXMonths(isSmallScreen ? 5 : 11);
  const expenseTypes = Object.values(ExpenseType);

  const series = expenseTypes.map((type) => {
    const data = monthsWithYear.map((monthWithYear) => {
      const [month, year] = monthWithYear.split(" ");
      const expensesInMonth = expenses[year]?.[month] || [];
      const totalForType = expensesInMonth
        .filter((expense) => expense.type === type)
        .reduce((sum, expense) => sum + expense.amount, 0);
      return Math.round(totalForType);
    });
    return {
      name: type,
      data,
    };
  });

  return series;
};

export const getTotalExpensesByTypeAndMonth = (
  selectedMonth: string,
  expenses: GroupedExpenses
): number[] => {
  const [month, year] = selectedMonth.split(" ");
  const expensesInMonth = expenses[year]?.[month];

  if (!expensesInMonth) {
    return [];
  }

  const expenseTypes = Object.values(ExpenseType);
  const expensesByType: { [type: string]: number } = {};

  expenseTypes.forEach((type) => {
    expensesByType[type] = 0;
  });

  expensesInMonth.forEach((expense) => {
    expensesByType[expense.type] += expense.amount;
  });

  return expenseTypes.map((type) => Math.round(expensesByType[type]));
};

export const getExpensesByMonth = (
  selectedMonth: string,
  expenses: GroupedExpenses
): Expense[] => {
  const [month, year] = selectedMonth.split(" ");
  const monthsExpenses = expenses[year]?.[month];
  if (monthsExpenses) {
    return monthsExpenses;
  }
  return [];
};

export const createCurrencyFormatter = (maximumFractionDigits: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: maximumFractionDigits,
  });
};

export function unformatCurrency(value: string) {
  return Number(value.replace(/[$,]+/g, ""));
}
