import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Expense } from "../../../models/expense";
import {
  getCurrentMonthAndLastXMonths,
  getSpendingForExpenseType,
} from "../../../services/chart-service";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from "@mui/material";
import styles from "./total-spending-history-by-expense.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface TotalSpendingHistoryByExpenseProps {
  expenses: {
    [year: string]: { [month: string]: Expense[] };
  };
}

export default function TotalSpendingHistoryByExpense(
  props: TotalSpendingHistoryByExpenseProps
) {
  const budgetByType = useSelector((state: RootState) => state.budgetByType);
  const [selectedExpense, setSelectedExpense] = useState(
    Object.keys(budgetByType)[0]
  );
  const [series, setSeries] = useState(
    getSpendingForExpenseType(props.expenses, selectedExpense)
  );
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updatedSeries = getSpendingForExpenseType(
      props.expenses,
      selectedExpense
    );
    setSeries(updatedSeries);
  }, [props.expenses, selectedExpense]);

  const budgetMax = budgetByType[selectedExpense];

  const options: ApexCharts.ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    series: [
      {
        name: selectedExpense,
        data: isSmallScreen ? series.slice(0, 6) : series,
      },
    ],
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      type: "category",
      categories: getCurrentMonthAndLastXMonths(isSmallScreen ? 5 : 11),
    },
    yaxis: {
      title: {
        text: "Spending",
      },
      labels: {
        formatter: function (value) {
          return `$${value}`;
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: budgetMax,
          borderColor: "#FF0000",
          label: {
            borderColor: "#FF0000",
            style: {
              color: "#fff",
              background: "#FF0000",
            },
            text: `Budget ($${budgetMax})`,
          },
        },
      ],
    },
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedExpense(event.target.value);
  };

  return (
    <div>
      <FormControl>
        <InputLabel id="month-label">Expense</InputLabel>
        <Select
          size="small"
          value={selectedExpense}
          onChange={handleChange}
          label="Expense"
        >
          {Object.keys(budgetByType).map((expenseType) => (
            <MenuItem key={expenseType} value={expenseType}>
              {expenseType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ReactApexChart
        className={styles.chart}
        options={options}
        series={options.series}
        type="line"
        height={window.innerHeight * 0.4}
      />
    </div>
  );
}
