import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Expense } from "../../models/expense";
import apiService from "../../services/api-service";
import { useDispatch } from "react-redux";
import { deleteExpense } from "../../store/actions";

interface ConfirmDialogProps {
  open: boolean;
  handleClose: () => void;
  expenseToDelete: Expense | null;
}

const ConfirmDialog = ({
  open,
  handleClose,
  expenseToDelete,
}: ConfirmDialogProps) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    if (expenseToDelete) {
      apiService.deleteExpense(expenseToDelete);
      dispatch(deleteExpense(expenseToDelete));
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Expense</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this expense?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          No
        </Button>
        <Button onClick={handleDelete} color="primary" variant="contained">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
